@import './abstracts/variables';
@import './abstracts/mixins';
@import './base/reset';
@import './components/app';

body {
  background-color: $primary-color;
  font-family: $font-stack;

  a {
    color: $primary-text-color;
    text-decoration: none;
  }
}

.copyright {
  font-size: 0.7rem;
  color: $primary-text-color;
  text-align: center;
  padding: 0.625rem;
}

.ril__toolbar {
  background-color: transparent;

  .ril-toolbar__item {
    button {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      width: 35px;
      height: 35px;
    }
  }

  .ril-zoom-in, .ril-zoom-out{
    display: none;
  }

  .ril__toolbarRightSide {
    padding-right: 10px;
  }
}

.ril__navButtonPrev, .ril__navButtonNext{ 
  background-size: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px 15px 20px 25px;
}
