$font-stack: ("museo-sans",sans-serif);
$primary-color: #1b1b1b;
$secondary-color: #353535;
$primary-text-color: #fff;
$link-color: #a1a1a1;
$link-hover-color: #fff;
$menubar-link-hover-color: #111;
$button-press-color: #474747;

//Breakpoints

//Very large tablets/computers-> mainContainer slightly larger (1350px)
$screen-huge-max: 84.38em;

//Large tablets/computers-> mainContainer 100% width (1200px)
$screen-xl-max: 75em;

//Tablets and small computers-> 4 images in a row instead of 6 in the Gallery (1000px)
$screen-lg-max: 62.5em;

//Small tablets-> Change to mobile view (800px)
$screen-md-max: 50em;

//Small tablets and large smartphones->2 images in a row instead of 4 in the Gallery (600px)
$screen-sm-max: 37.5em;

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

